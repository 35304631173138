
.random-paper {
    display: flex;
    flex-direction: column;
    height: 96%;
    padding: 30px;
    color: #13131b;
    .form {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .form-box {
        flex: 1;
        width: 520px;
        span {
            margin: 0 10px;
            white-space: nowrap;
        }
        .set-type-question {
            .hint {
                color: #999;
            }
            ::v-deep .el-input {
                width: 180px;
            }
            .type-input-list {
                width: 555px;
                transform: translateX(-9%);
                .type-input-item:nth-child(1) {
                    margin-top: 10px;
                }
                .type-input-item {
                    display: flex;
                    margin-bottom: 20px;
                }
            }
        }
        .total-score {
            transform: translateX(39%);
            ::v-deep .el-input {
                width: 180px;
            }
        }
        .type-form-item {
            margin-bottom: 0;
        }
    }
    .footer-button {
        display: flex;
        justify-content: center;
        margin-bottom: 28px;
        ::v-deep .el-button {
            width: 80px;
            height: 40px;
        }
        ::v-deep .blue-button {
            background-color: #1122d8;
            border-color: #1122d8;
        }
    }
}
//去除number input的指示器
/* chrome */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
